import type { NextPage } from 'next';
import { Heading, Logo, Text } from '@corellium/metal';

const Error404: NextPage = () => (
  <div className="flex h-full w-full items-center justify-center">
    <div className="flex w-full max-w-[18.75rem] flex-col gap-8 text-center">
      <div className="flex flex-col items-center gap-6">
        <Logo type="symbol" />
        <div className="flex flex-col gap-4">
          <Heading size="sm">404: Page not found</Heading>
          <Text className="text-muted-foreground">
            Looks like this page doesn&apos;t exist!
          </Text>
        </div>
      </div>
    </div>
  </div>
);

export default Error404;
